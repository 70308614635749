'use client';

import 'client-only';

import { ToolsTalentAppLinkFeature } from '../common/features/tools-talent-app-link-feature';
import { createUseFlag } from './create-use-flag';

export const useToolsTalentAppLinkFeature = createUseFlag(
  ToolsTalentAppLinkFeature
);
